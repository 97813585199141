import React from 'react';
import { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Home';
import ResortPage from './ResortPage';
import ResortList from './ResortList';
import ResortDetail from './ResortDetail';
import ScrollToTop from './ScrollToTop';
import Terms from './terms';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { config } from './Constants';

const trackingId = config.gaId.id;

ReactGA.initialize(trackingId);

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
});

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Route
            render={({ location }) => (
              <Switch location={location}>
                <Route exact path='/' key='home'>
                  <Home />
                </Route>
                <Route exact path='/resort_page' key='resort_page'>
                  <ResortPage />
                </Route>
                <Route exact path='/resort_list' key='resort_list'>
                  <ResortList />
                </Route>
                <Route exact path='/resort_detail' key='resort_detail'>
                  <ResortDetail />
                </Route>
                <Route exact path='/terms' key='terms'>
                  <Terms />
                </Route>
              </Switch>
            )}
          />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
