// Constants
const prod = {
  url: {
    API_URL: process.env.React_App_prod_api_url,
  },
  imageCDN: {
    url: 'https://d27ltl356tgk57.cloudfront.net/',
  },
  key: {
    google_key: process.env.React_App_google_api_key,
  },
  gaId: {
    id: 'UA-168744068-1',
  },
  enable_widgets: true,
  emailID: process.env.React_App_emailjs_ID,
};

const dev = {
  url: {
    API_URL: 'http://localhost:5000/api/resorts/',
  },
  imageCDN: {
    url: 'https://d27ltl356tgk57.cloudfront.net/',
  },
  key: {
    google_key: process.env.React_App_google_api_key,
  },
  gaId: {
    id: 'UA-0000-1',
  },
  enable_widgets: false,
  emailID: process.env.React_App_emailjs_ID,
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
