import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { config } from './Constants';
import FormNew from './FormNew.js';
import ReactGA from 'react-ga';
import HomeSteps from './HomeSteps';
import wallimg from './img/xlarge.png';
import logo from './resortLogos/Test.png';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FormatListNumberedSharpIcon from '@material-ui/icons/FormatListNumberedSharp';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import HomeSharpIcon from '@material-ui/icons/HomeSharp';
import CasinoIcon from '@material-ui/icons/Casino';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';

var imageUrl = config.imageCDN.url;
let xsmall = imageUrl + 'xsmall_new.jpg';
let small = imageUrl + 'small_new.jpg';
let medium = imageUrl + 'medium_new.jpg';
let large = imageUrl + 'large_new.jpg';
let xlarge = imageUrl + 'xlarge_new.jpg';
let siteLogo = imageUrl + 'logo.png';

let xsmall_webp = imageUrl + 'xsmall_new_webp.webp';
let small_webp = imageUrl + 'small_new_webp.webp';
let medium_webp = imageUrl + 'medium_new_webp.webp';
let large_webp = imageUrl + 'large_new_webp.webp';
let xlarge_webp = imageUrl + 'xlarge_new_webp.webp';

var url = config.url.API_URL;

class Home extends Component {
  constructor(props) {
    super(props);
    this.section1 = React.createRef();
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview('Home');
  }

  handleOnClick(event) {
    this.section1.current.scrollIntoView({ behaviour: 'smooth' });
  }

  render() {
    return (
      <React.Fragment>
        <Helmet title='SkiWhere' />

        <picture alt='img' className='wallpaper' id='home-content-desktop'>
          <source
            className='wallpaper'
            srcSet={`${xsmall_webp} 300w, ${small_webp} 640w, ${medium_webp} 1280w, ${large_webp} 1920w, ${xlarge_webp} 2400w`}
            alt='img'
          ></source>
          <img
            className='wallpaper'
            srcSet={`${xsmall} 300w, ${small} 640w, ${medium} 1280w, ${large} 1920w, ${xlarge} 2400w`}
            alt='img'
          ></img>
        </picture>
        <img
          className='wallpaper'
          id='home-content-mobile'
          src={small}
          alt='img'
        ></img>
        <img src={logo} alt='img' className='new-logo'></img>
        <section className='new-home'>
          <section className='new-section'>
            <div className='logo-title'>
              <h1>Let us find your perfect ski resort.</h1>
            </div>
            <div className='title-text'>
              <h5>
                We have reviewed hundreds of resorts against fifty metrics to
                create a curated list of ski and snowboard destinations to match
                every preference across Europe and North Amerca.
              </h5>
              <h5>
                Tell us what you look for in a holiday and we'll find your dream
                resort and provide you with all the information you need to plan
                and book your next holiday.
              </h5>
            </div>
            <button className='button-more' onClick={this.handleOnClick}>
              How does it work
            </button>
          </section>
          <section className='new-form'>
            <FormNew></FormNew>
          </section>
        </section>
        <span ref={this.section1}></span>
        <HomeSteps history={this.props.history} />
        <br></br>
        <br></br>
        <br></br>
        <div className='home-footer'>
          <SimpleBottomNavigation history={this.props.history} />
        </div>
      </React.Fragment>
    );
  }
}

function SimpleBottomNavigation(props) {
  const [value, setValue] = React.useState(0);

  function HandleOnClick(event, newValue) {
    setValue(newValue);

    if (newValue === 2) {
      props.history.push({
        pathname: '/resort_list',
      });
    }

    if (newValue === 1) {
      getLuckyData(props.history);
    }
  }

  return (
    <BottomNavigation value={value} onChange={HandleOnClick} showLabels>
      <BottomNavigationAction label='Home' icon={<HomeSharpIcon />} />
      <BottomNavigationAction label='Lucky dip' icon={<CasinoIcon />} />
      <BottomNavigationAction
        label='Resort list'
        icon={<FormatListNumberedSharpIcon />}
      />
    </BottomNavigation>
  );
}

const getLuckyData = async (props) => {
  const getResponse = await axios.get(url + 'lucky_dip');
  props.push({
    pathname: '/resort_page',
    state: { detail: { ...getResponse.data } },
  });
};

export default withRouter(Home);

/*
          this.props.history.push({
            pathname: '/resort_page',
            state: { detail: this.state.responseData },
          });


const getData = async (url) => {
  const getResponse = await axios.get(url);
  return { ...getResponse.data };

  var url = config.url.API_URL;
};
          <picture alt='img' className='wallpaper' id='content-desktop'>
            <source
              className='wallpaper'
              srcSet={`${xsmall_webp} 300w, ${small_webp} 640w, ${medium_webp} 1280w, ${large_webp} 1920w, ${xlarge_webp} 2400w`}
              alt='img'
            ></source>
            <img
              className='wallpaper'
              srcSet={`${xsmall} 300w, ${small} 640w, ${medium} 1280w, ${large} 1920w, ${xlarge} 2400w`}
              alt='img'
            ></img>
          </picture>
          <img
            alt='img'
            className='mob-wallpaper'
            id='content-mobile'
            src={small}
          ></img>
          <img
            alt='img'
            className='mob-wallpaper'
            id='content-mobile-small'
            src={xsmall}
          ></img>




                   <picture alt='img' className='wallpaper'>
            <source
              className='wallpaper'
              srcSet={`${xsmall_webp} 300w, ${small_webp} 640w, ${medium_webp} 1280w, ${large_webp} 1920w, ${xlarge_webp} 2400w`}
              alt='img'
            ></source>
            <img
              className='wallpaper'
              srcSet={`${xsmall} 300w, ${small} 640w, ${medium} 1280w, ${large} 1920w, ${xlarge} 2400w`}
              alt='img'
            ></img>
          </picture>

*/
