import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { config } from './Constants';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FormatListNumberedSharpIcon from '@material-ui/icons/FormatListNumberedSharp';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import HomeSharpIcon from '@material-ui/icons/HomeSharp';
import CasinoIcon from '@material-ui/icons/Casino';
import { Helmet } from 'react-helmet';
import AutoComplete from './searchBar';
import { ResortDesc, MetricTable } from './ResortPage';
import ReactGA from 'react-ga';

const pageFactor = 20;
var url = config.url.API_URL;
var imageUrl = config.imageCDN.url;
let siteLogo = imageUrl + 'logo.png';

class ResortList extends Component {
  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnSort = this.handleOnSort.bind(this);
  }

  state = {
    data: [],
    startArray: 0,
    stopArray: pageFactor,
    currentPage: 1,
  };

  componentDidMount = async (e) => {
    var q_url = url + 'all';
    const responseData = await getData(q_url);
    let myData = Object.keys(responseData).map((key) => responseData[key]);

    var nameData = [];
    var i = 0;
    for (i = 0; i < myData.length; i++) {
      nameData.push(myData[i]['resort_name'] + '(' + myData[i]['ID'] + ')');
    }
    nameData.sort();

    myData.sort(function (resort_a, resort_b) {
      if (resort_a['calc_rank'] < resort_b['calc_rank']) return -1;
      if (resort_a['calc_rank'] > resort_b['calc_rank']) return 1;
    });

    this.setState({
      data: myData,
      originalData: myData,
      nameData: nameData,
    });

    ReactGA.pageview('Resort List');

    ReactGA.event({
      category: 'Resort_list',
      action: 'User viewed the resort list',
    });
  };

  handleOnClick(event) {
    if (
      event.target.name === 'right' &&
      event.target.value > 1 &&
      this.state.currentPage < event.target.value
    ) {
      this.setState({
        startArray: this.state.startArray + pageFactor,
        stopArray: this.state.stopArray + pageFactor,
        currentPage: this.state.currentPage + 1,
      });
    } else if (
      event.target.name === 'right' &&
      event.target.value >= 1 &&
      this.state.currentPage >= event.target.value
    ) {
      alert('Final page reached!');
    } else if (this.state.currentPage > 2) {
      {
        this.setState({
          startArray: this.state.startArray - pageFactor,
          stopArray: this.state.stopArray - pageFactor,
          currentPage: this.state.currentPage - 1,
        });
      }
    } else if (event.target.name === 'resort-detail') {
    } else {
      this.setState({
        startArray: 0,
        stopArray: pageFactor,
        currentPage: 1,
      });
    }
  }

  handleOnSort(event) {
    if (event.target.name === 'reset') {
      this.setState({ data: this.state.originalData });
    } else {
      let mySortableData = Object.keys(this.state.data).map(
        (key) => this.state.data[key]
      );
      var metric = event.target.name;
      var new_results = rankResults(mySortableData, String(metric));
      this.setState({
        data: new_results,
      });
    }
  }

  render() {
    let myData = Object.keys(this.state.data).map(
      (key) => this.state.data[key]
    );
    var finalPage = Math.ceil(myData.length / pageFactor);
    var displayData = myData.slice(this.state.startArray, this.state.stopArray);
    var index_increase = (this.state.currentPage - 1) * 20;
    return (
      <div>
        <Helmet title='Resort List' />
        <div className='paginationBtn-container'>
          <button
            onClick={this.handleOnClick}
            name='left'
            value={finalPage}
            className='paginationBtn paginationLeft'
          ></button>
          <button
            onClick={this.handleOnClick}
            name='right'
            value={finalPage}
            className='paginationBtn paginationRight'
          ></button>
          <div className='paginationText'>
            Page {this.state.currentPage} of {finalPage}
          </div>
        </div>
        <div className='list-header'>
          <p className='site-logo-header-text'>
            See the full list of resorts below
          </p>
        </div>
        <br></br>
        <div className='search-bar'></div>
        <br></br>
        <h2 className='header-sort'>Sort or search for resorts:</h2>
        <div className='resort-sort'>
          <button onClick={this.handleOnSort} name='apres' className='btn-sort'>
            Apres
          </button>
          <button
            onClick={this.handleOnSort}
            name='family_friendly'
            className='btn-sort'
          >
            Family friendliness
          </button>
          <button
            onClick={this.handleOnSort}
            name='non_ski'
            className='btn-sort'
          >
            Non skiing activities
          </button>
          <button
            onClick={this.handleOnSort}
            name='budget_index_reverse'
            className='btn-sort'
          >
            Budget
          </button>
          <button
            onClick={this.handleOnSort}
            name='lack_crowds'
            className='btn-sort'
          >
            Avoiding crowds
          </button>
          <button
            onClick={this.handleOnSort}
            name='off_piste'
            className='btn-sort'
          >
            Off piste
          </button>
          <button
            onClick={this.handleOnSort}
            name='beginner_ski_area_km'
            className='btn-sort'
          >
            Beginner ski area
          </button>
          <button
            onClick={this.handleOnSort}
            name='intermediate_ski_area_km'
            className='btn-sort'
          >
            Intermediate ski area
          </button>
          <button
            onClick={this.handleOnSort}
            name='expert_ski_area_km'
            className='btn-sort'
          >
            Expert ski area
          </button>
          <button onClick={this.handleOnSort} name='reset' className='btn-sort'>
            Reset
          </button>
          <AutoComplete
            options={this.state.nameData}
            history={this.props.history}
          />
        </div>
        <div>
          {displayData.map((resort, index) => (
            <ResortMini
              key={resort._id}
              data={resort}
              history={this.props.history}
              allData={myData}
              index={index + index_increase}
            ></ResortMini>
          ))}
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className='home-footer'>
          <SimpleBottomNavigation history={this.props.history} />
        </div>
      </div>
    );
  }
}

/*       <h3 className='header-3 wallpaper-header'>
Find your dream ski destination.
</h3> */
class ResortMini extends Component {
  constructor(props) {
    super(props);
    this.handleOnBook = this.handleOnBook.bind(this);
  }

  handleOnBook(event) {
    ReactGA.event({
      category: 'Resort_list',
      action: 'User selected a resort from the result list',
      label: this.props.data['resort_name'],
    });
    getResortData(this.props.history, this.props.data['ID']);
  }

  render() {
    let file = this.props.data['ID'];
    let country = this.props.data['country'];
    let logo = imageUrl + file + '.png';
    var resort_bullets = [
      this.props.data['resort_bullet_1'],
      this.props.data['resort_bullet_2'],
      this.props.data['resort_bullet_3'],
      this.props.data['resort_bullet_4'],
      this.props.data['ski_bullet_1'],
      this.props.data['ski_bullet_2'],
      this.props.data['ski_bullet_3'],
      this.props.data['ski_bullet_4'],
    ];

    country = imageUrl + country + '.png';

    return (
      <section className='resort-section'>
        <div className='resort-header'>
          <h2
            className='resort-header-title'
            value={this.props.data['resort_name']}
            onClick={this.handleOnBook}
            name='resort-detail'
          >
            {this.props.data['resort_name']}
          </h2>
        </div>
        <section className='resort-info-section'>
          <div className='resort-content-container-new'>
            <span className='resort-info-header'>
              <img src={logo} alt='icon' className='resort-logo-new'></img>{' '}
              <img src={country} alt='icon' className='resort-flag-new'></img>
              <ResortDesc
                bullets={resort_bullets}
                text={'Our key observations of this resort:'}
              ></ResortDesc>
            </span>
          </div>
          <MetricTable
            metrics={[
              this.props.data['apres'],
              this.props.data['family_friendly'],
              this.props.data['non_ski'],
              this.props.data['budget'],
              this.props.data['ski_area'],
              this.props.data['beginner_score'],
              this.props.data['intermediate_score'],
              this.props.data['expert_score'],
              this.props.data['off_piste'],
              this.props.data['lack_crowds'],
              this.props.data['snow_reliability'],
            ]}
            labels={[
              'Apres',
              'Family friendly',
              'Non skiing',
              'Budget',
              'Total ski area (km)',
              'Beginner area',
              'Intermediate area',
              'Expert area',
              'Off piste',
              'Avoiding crowds',
              'Snow reliability',
            ]}
          ></MetricTable>
        </section>
        <div className='list-footer'></div>
      </section>
    );
  }
}

function SimpleBottomNavigation(props) {
  const [value, setValue] = React.useState(3);

  function HandleOnClick(event, newValue) {
    setValue(newValue);
    if (newValue === 0) {
      props.history.push({
        pathname: '/',
      });
    }

    if (newValue === 1) {
      getLuckyData(props.history);
    }
  }

  return (
    <BottomNavigation value={value} onChange={HandleOnClick} showLabels>
      <BottomNavigationAction label='Home' icon={<HomeSharpIcon />} />
      <BottomNavigationAction label='Lucky dip' icon={<CasinoIcon />} />
      <BottomNavigationAction
        label='Resort list'
        icon={<FormatListNumberedSharpIcon />}
      />
    </BottomNavigation>
  );
}

function rankResults(resorts, metric) {
  resorts.sort(function (resort_a, resort_b) {
    if (resort_a[metric] > resort_b[metric]) return -1;
    if (resort_a[metric] < resort_b[metric]) return 1;
    if (
      resort_a[metric] === resort_b[metric] &&
      resort_a.calc_rank < resort_b.calc_rank
    )
      return -1;
    if (
      resort_a[metric] === resort_b[metric] &&
      resort_a.calc_rank > resort_b.calc_rank
    )
      return 1;
  });
  return resorts;
}

const getData = async (url) => {
  const getResponse = await axios.get(url);
  return { ...getResponse.data };
};

const getLuckyData = async (props) => {
  const getResponse = await axios.get(url + 'lucky_dip');
  props.push({
    pathname: '/resort_page',
    state: { detail: { ...getResponse.data } },
  });
};

const getResortData = async (props, query) => {
  const getResponse = await axios.get(url + 'find_resort/' + query);
  const data = { 0: { ...getResponse.data } };
  props.push({
    pathname: '/resort_page',
    state: { detail: data },
  });
};

export default withRouter(ResortList);
