import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: 'black',
  },
  formControl: { marginLeft: '4px', marginBottom: '15px' },
}));

const BlueCheckbox = withStyles({
  root: {
    color: '#039be5',
    '&$checked': {
      color: '#039be5',
    },
  },
})((props) => <Checkbox color='default' {...props} />);

export default function CheckboxesGroupNA(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl component='fieldset' className={classes.formControl}>
        <FormLabel component='legend'>North America</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[0]}
                onChange={props.handleChange}
                name='na_all'
              />
            }
            label='All'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[1]}
                onChange={props.handleChange}
                name='usa'
              />
            }
            label='USA'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[2]}
                onChange={props.handleChange}
                name='canada'
              />
            }
            label='Canada'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[3]}
                onChange={props.handleChange}
                name='na_none'
              />
            }
            label={'None'}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}
