import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { config } from './Constants';
import ScriptTag from 'react-script-tag';
import Checkbox from '@material-ui/core/Checkbox';
import Iframe from 'react-iframe';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FormatListNumberedSharpIcon from '@material-ui/icons/FormatListNumberedSharp';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import HomeSharpIcon from '@material-ui/icons/HomeSharp';
import CasinoIcon from '@material-ui/icons/Casino';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import ReactGA from 'react-ga';

var key = config.key.google_key;
var map_switch = config.enable_widgets;
var url = config.url.API_URL;
var imageUrl = config.imageCDN.url;

class ResortDetail extends Component {
  constructor(props) {
    super(props);
    this.handleTick = this.handleTick.bind(this);
    this.handleOnAccom = this.handleOnAccom.bind(this);
    this.handleOnFlight = this.handleOnFlight.bind(this);
  }

  state = {
    data: [],
    resortName: 'placeholder',
    resortCountry: 'placeholder',
    flightCheck: 'resort-content-container',
    accommodationCheck: 'resort-content-container',
    passCheck: 'resort-content-container',
    rentalCheck: 'resort-content-container',
    transferCheck: 'resort-content-container',
    insuranceCheck: 'resort-content-container',
    allComplete: false,
    completionCounter: 0,
  };

  componentDidMount() {
    this.setState({
      data: this.props.location.state,
      resortName: this.props.location.state['resort_name'],
      resortCountry: this.props.location.state['country'],
      destination: this.props.location.state['suggested_destination_1'],
      accommodation: this.props.location.state['suggested_accommodation_1'],
    });

    ReactGA.pageview('Resort Detail');
  }

  handleTick(event) {
    switch (event.target.checked) {
      case true:
        this.setState({
          [event.target.name]: 'resort-content-container-checked',
          completionCounter: this.state.completionCounter + 1,
        });
        break;
      case false:
        this.setState({
          [event.target.name]: 'resort-content-container',
          completionCounter: this.state.completionCounter - 1,
        });
        break;
      default:
        break;
    }

    if (this.state.completionCounter === 5 && event.target.checked) {
      this.setState({ allComplete: true });
    } else {
      this.setState({ allComplete: false });
    }
  }

  handleOnFlight(event) {
    console.log(event.target.id);
    this.setState({ destination: event.target.id });
  }

  handleOnAccom(event) {
    console.log(event.target.id);
    this.setState({ accommodation: event.target.id });
  }

  render() {
    let resort = this.state.data;
    let file = this.state.data['resort_name'];
    let country = this.state.data['country'];
    let res_id = this.state.data['ID'];
    let logo = imageUrl + res_id + '.png';
    let banner = imageUrl + res_id + '_wall_mobile.jpg';

    if (map_switch) {
      var google_url =
        'https://www.google.com/maps/embed/v1/place?key=' +
        key +
        '&q=' +
        file +
        ',' +
        country;
    }

    let child_day_price = this.state.data['ski pass price - child day'];
    let child_week_price = this.state.data['ski pass price - adults day'];
    let adult_day_price = this.state.data['ski pass price - child 6'];
    let adult_week_price = this.state.data['ski pass price - adults 6 '];
    let rental_name_1 = 'snowbrainer';
    let rental_name_2 = 'bestpriceskirental';
    let rental_link_1 = 'https://www.snowbrainer.com/en/ski-rental';
    let rental_link_2 = 'https://www.best-price-ski-rental.com/en/ski-rental';
    let transfer_name_1 = 'travelsupermarket';
    let transfer_link_1 =
      'https://www.travelsupermarket.com/en-gb/airport-transfers/';

    let insurance_link_1 =
      'https://www.travelsupermarket.com/en-gb/travel-insurance/';
    country = imageUrl + country + '.png';

    let website_link = this.state.data['Website'];

    return (
      <section className='resort-section'>
        <Helmet title='Booking checklist'></Helmet>
        <div className='resort-header'>
          <img src={logo} alt='icon' className='resort-logo'></img>
          <h2
            onClick={this.handleOnBook}
            value={resort['resort_name']}
            className='resort-header-title'
          >
            {resort['resort_name']}
          </h2>
          <img src={country} alt='icon' className='resort-flag'></img>
        </div>
        <section className='resort-info-section'>
          <div className='resort-content-container-new'>
            <div
              id='content-desktop'
              className='parallax-new'
              style={{
                backgroundImage: `url(${banner})`,
              }}
              alt='resort-banner'
            ></div>
            <img id='content-mobile' src={banner} alt='resort-banner'></img>
          </div>
          <div className='resort-content-container-new'>
            <h3 className='metric-header'>Destination</h3>
            <p>This is where you're headed:</p>
            <div className='resort-content-sub-container'>
              <Iframe
                width='500'
                height='300'
                frameborder='0'
                src={google_url}
                allowfullscreen
              ></Iframe>
            </div>
          </div>
        </section>
        <div className={this.state.flightCheck + ' resort-content-skyscanner'}>
          <h3 className='metric-header'>Flights</h3>
          <div className='resort-content-sub-container'>
            <table className='dest-table'>
              <tr>
                <th className='th-dest'>Suggested airports</th>
                <th className='th-dest'>Resort transfer time</th>
              </tr>
              <tr>
                <td className='td-dest'>{resort.suggested_destination_1}</td>
                <td className='td-dest'>
                  {resort.suggested_destination_1_time}
                </td>
              </tr>
              <tr>
                <td className='td-dest'>{resort.suggested_destination_2}</td>
                <td className='td-dest'>
                  {resort.suggested_destination_2_time}
                </td>
              </tr>
              <tr>
                <td className='td-dest'>{resort.suggested_destination_3}</td>
                <td className='td-dest'>
                  {resort.suggested_destination_3_time}
                </td>
              </tr>
            </table>
          </div>
          <br></br>
          <br></br>
          <div className='resort-content-sub-container'>
            <div
              id={this.state.destination}
              data-skyscanner-widget='SearchWidget'
              className='flight-widget'
              data-destination-name={"'" + this.state.destination + "'"}
            ></div>
            <ScriptTag
              type='text/javascript'
              src='https://widgets.skyscanner.net/widget-server/js/loader.js'
              async
            ></ScriptTag>
          </div>
          <div className='check-section'>
            Tick once complete:
            <Checkbox
              onChange={this.handleTick}
              name='flightCheck'
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        </div>
        <div
          className={
            this.state.accommodationCheck + ' resort-content-skyscanner'
          }
        >
          <h3 className='metric-header'>Accommodation</h3>
          <div className='resort-content-sub-container'>
            <table className='dest-table'>
              <tr>
                <th className='th-dest'>Suggested places to stay</th>
              </tr>
              <tr>
                <td className='td-dest'>{resort.suggested_accommodation_1}</td>
              </tr>
              <tr>
                <td className='td-dest'>{resort.suggested_accommodation_2}</td>
              </tr>
              <tr>
                <td className='td-dest'>{resort.suggested_accommodation_3}</td>
              </tr>
            </table>
          </div>
          <br></br>
          <br></br>
          <div className='resort-content-sub-container'>
            <div
              data-skyscanner-widget='HotelSearchWidget'
              className='flight-widget'
              data-destination-name={"'" + this.state.accommodation + "'"}
            ></div>
            <ScriptTag
              type='text/javascript'
              src='https://widgets.skyscanner.net/widget-server/js/loader.js'
              async
            ></ScriptTag>
          </div>
          <div className='check-section'>
            Tick once complete:
            <Checkbox
              onChange={this.handleTick}
              name='accommodationCheck'
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        </div>
        <div className={this.state.passCheck + ' resort-content-skyscanner'}>
          <h3 className='metric-header'>Ski pass (last season)</h3>
          <table className='detail-table'>
            <tr>
              <th>Child day pass</th>
              <th>Adult day pass</th>
              <th>Child 6 day pass</th>
              <th>Adult 6 day pass </th>
              <th>Resort Website</th>
            </tr>
            <tr>
              <td className='td-detail'>{child_day_price}</td>
              <td className='td-detail'>{adult_day_price}</td>
              <td className='td-detail'>{child_week_price}</td>
              <td className='td-detail'>{adult_week_price}</td>
              <td className='td-detail'>
                <a href={website_link}>{website_link}</a>
              </td>
            </tr>
          </table>
          <div className='check-section'>
            Tick once complete:
            <Checkbox
              onChange={this.handleTick}
              name='passCheck'
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        </div>
        <div className={this.state.rentalCheck + ' resort-content-skyscanner'}>
          <h3 className='metric-header'>Rental</h3>
          <p>
            Booking rental equipment is a crucial step for organising your
            holiday - unfortunately we do not have any partners to direct you
            too. Our recommendation for rental is first to check whether your
            accommodation either has their own facilities or recommended
            facilities, otherwise book your equipment with a shop near the ski
            slopes for convenience. Each person in your party will need at a
            minimum a set of skis or a snowboard, boots, a helmet, goggles, a
            winter jacket and snow trousers / salopettes.
            <br></br> <br></br>Usually rental does not need to be booked too
            much in advance but it is good to get it sorted as soon as possible
            while shops have plenty of equipment available!
          </p>
          <div className='check-section'>
            Tick once complete:
            <Checkbox
              onChange={this.handleTick}
              name='rentalCheck'
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        </div>
        <div
          className={this.state.transferCheck + ' resort-content-skyscanner'}
        >
          <h3 className='metric-header'>Transfers</h3>
          <p>Recommended websites:</p>
          <a href={transfer_link_1} target='_blank' rel='noopener noreferrer'>
            <img
              src={`${process.env.PUBLIC_URL}/third_party_logos/${transfer_name_1}.png`}
              alt='icon'
              className='third-logo'
            ></img>
          </a>
          <div className='check-section'>
            Tick once complete:
            <Checkbox
              onChange={this.handleTick}
              name='transferCheck'
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        </div>
        <div
          className={this.state.insuranceCheck + ' resort-content-skyscanner'}
        >
          <h3 className='metric-header'>Insurance</h3>
          <p>Recommended websites:</p>
          <a href={insurance_link_1} target='_blank' rel='noopener noreferrer'>
            <img
              src={`${process.env.PUBLIC_URL}/third_party_logos/${transfer_name_1}.png`}
              alt='icon'
              className='third-logo'
            ></img>
          </a>
          <div className='check-section'>
            Tick once complete:
            <Checkbox
              onChange={this.handleTick}
              name='insuranceCheck'
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        </div>
        <CheckSuccess isSuccess={this.state.allComplete} />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className='home-footer'>
          <SimpleBottomNavigation history={this.props.history} />
        </div>
      </section>
    );
  }
}

function SuccessMessage(props) {
  return (
    <div className='success-message' id='successDiv'>
      <h3>Congratulations!</h3>
      <h4>
        You have booked your skiing holiday! Please get in touch through the
        'Contact Us' page if you have any questions.
      </h4>
    </div>
  );
}

function AwaitingSuccess(props) {
  return <div></div>;
}

function CheckSuccess(props) {
  const isSuccess = props.isSuccess;
  if (isSuccess) {
    return <SuccessMessage />;
  }
  return <AwaitingSuccess />;
}

function SimpleBottomNavigation(props) {
  const [value, setValue] = React.useState(4);

  function HandleOnClick(event, newValue) {
    setValue(newValue);
    if (newValue === 0) {
      props.history.push({
        pathname: '/',
      });
    } else if (newValue === 3) {
      props.history.push({
        pathname: '/resort_list',
      });
    } else if (newValue === 2) {
      getLuckyData(props.history);
    }
  }

  return (
    <BottomNavigation value={value} onChange={HandleOnClick} showLabels>
      <BottomNavigationAction label='Home' icon={<HomeSharpIcon />} />
      <BottomNavigationAction label='Lucky dip' icon={<CasinoIcon />} />
      <BottomNavigationAction
        label='Resort list'
        icon={<FormatListNumberedSharpIcon />}
      />
    </BottomNavigation>
  );
}

const getLuckyData = async (props) => {
  const getResponse = await axios.get(url + 'lucky_dip');
  props.push({
    pathname: '/resort_page',
    state: { detail: { ...getResponse.data } },
  });
};

export default withRouter(ResortDetail);
