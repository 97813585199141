import React from 'react';
import { Select, FormHelperText } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    fontSize: 10,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function FormSelect(props) {
  const classes = useStyles();
  if (props.name === 'Budget') {
    return (
      <div>
        <FormControl error={props.error} className={classes.formControl}>
          <InputLabel id={props.name}>
            {props.name.charAt(0).toUpperCase() + props.name.substring(1)}
          </InputLabel>
          <Select
            labelId={props.name}
            native
            onChange={props.action}
            className='mat-select'
          >
            <option aria-label='None' value='' />
            <option value={1}>1 - I want luxury</option>
            <option value={2}>2 - I don't mind spending a bit more</option>
            <option value={3}>3 - Average</option>
            <option value={4}>4 - I'd like cheaper</option>
            <option value={5}>5 - I'm on a tight budget!</option>
          </Select>
          <FormHelperText>{props.errorText}</FormHelperText>
        </FormControl>
      </div>
    );
  } else if (props.name !== 'Preferred Country') {
    return (
      <div>
        <FormControl error={props.error} className={classes.formControl}>
          <InputLabel id={props.name}>
            {props.name.charAt(0).toUpperCase() + props.name.substring(1)}
          </InputLabel>
          <Select
            labelId={props.name}
            native
            onChange={props.action}
            className='mat-select'
          >
            <option aria-label='None' value='' />
            <option value={1}>1 - Not important</option>
            <option value={2}>2 - Less important</option>
            <option value={3}>3 - Somewhat important</option>
            <option value={4}>4 - Important</option>
            <option value={5}>5 - I need this</option>
          </Select>
          <FormHelperText>{props.errorText}</FormHelperText>
        </FormControl>
      </div>
    );
  } else
    return (
      <div>
        <FormControl error={props.error} className={classes.formControl}>
          <InputLabel id={props.name}>
            {props.name.charAt(0).toUpperCase() + props.name.substring(1)}
          </InputLabel>
          <Select
            labelId={props.name}
            native
            onChange={props.action}
            className='mat-select'
          >
            <option aria-label='None' value='' />
            <option value={0}>I don't mind</option>
            <option value={'Andorra'}>Andorra</option>
            <option value={'Austria'}>Austria</option>
            <option value={'Bulgaria'}>Bulgaria</option>
            <option value={'Finland'}>Finland</option>
            <option value={'France'}>France</option>
            <option value={'Germany'}>Germany</option>
            <option value={'Italy'}>Italy</option>
            <option value={'Norway'}>Norway</option>
            <option value={'Spain'}>Spain</option>
            <option value={'Switzerland'}>Switzerland</option>
          </Select>
          <FormHelperText>{props.errorText}</FormHelperText>
        </FormControl>
      </div>
    );
}

//<option value={0}>USA / Canada / Japan - coming soon!</option>
