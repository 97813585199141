import React from 'react';
import { Component } from 'react';
import resortinfo from './img/resortinfo.PNG';

class HomeSteps extends Component {
  constructor(props) {
    super(props);
    this.handleOnTerms = this.handleOnTerms.bind(this);
  }
  handleOnTerms() {
    this.props.history.push({
      pathname: '/terms',
    });
  }
  render() {
    return (
      <React.Fragment>
        <section className='home-section'>
          <section className='resort-info-section resort-steps-section'>
            <div className='home-content-container-new'>
              <h3 className='home-sub-header'>
                Step 1: Tell us your preferences
              </h3>
              <ul className='home-ul'>
                <li className='home-li'>
                  <b>Country</b> - Do you have a specific country in mind or
                  would you like to compare resorts across multiple countries?
                </li>
                <li className='home-li'>
                  <b>Skiing level</b> - What skiing level do you need the resort
                  to cater to? Do you / does your group lean towards a certain
                  difficulty?
                </li>
                <li className='home-li'>
                  <b>Resort preferences</b> - are you looking for particular
                  things in a resort across budget, apres (nightlife), family
                  friendliness, off piste (skiing / boarding outside the marked
                  area) and non skiing activities?
                </li>
              </ul>
              <br></br>
              <h3 className='home-sub-header'>
                Step 2: We'll find the best resorts for you
              </h3>
              <p className='home-p'>
                We'll show you the resorts that match your criteria best and
                give you all the information you need to select your ideal
                destination. We'll show you the top five resorts that match your
                criteria.
              </p>
              <img className='resort-info' src={resortinfo} alt='img'></img>
              <br></br>
              <h3 className='home-sub-header'>
                Step 3: We'll walk you through the booking process
              </h3>
              <p className='home-p'>
                Use our booking checklist to plan and book your dream skiing /
                snowboarding holiday. All the information you need to book your
                accommodation, flights, transfers, insurance & rental is
                provided.
              </p>
              <br></br>
              <h3 className='home-sub-header'>
                Step 4: Embark on the holiday of a lifetime!
              </h3>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <p className='terms-nav' onClick={this.handleOnTerms}>
                Terms & Conditions
              </p>
            </div>
          </section>
        </section>
      </React.Fragment>
    );
  }
}

export default HomeSteps;

/*






    

*/
