import React from 'react';
import { Component } from 'react';
import { PureComponent } from 'react';
import Chart from 'chart.js';
import './App.css';
import { withRouter } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FormatListNumberedSharpIcon from '@material-ui/icons/FormatListNumberedSharp';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import HomeSharpIcon from '@material-ui/icons/HomeSharp';
import { Helmet } from 'react-helmet';
import { config } from './Constants';
import ReactGA from 'react-ga';

var imageUrl = config.imageCDN.url;
let siteLogo = imageUrl + 'logo.png';

class ResortPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmitReturn = this.handleSubmitReturn.bind(this);
  }

  state = {
    chart_id: '',
    data: [],
    data_1: [],
    data_2: [],
    data_3: [],
    startArray: 0,
    stopArray: 10,
    currentPage: 1,
  };

  componentDidMount() {
    try {
      this.setState({
        data: this.props.location.state.detail,
        data_1: this.props.location.state.detail[0],
        data_2: this.props.location.state.detail[1],
        data_3: this.props.location.state.detail[2],
      });
    } catch (e) {
      this.props.history.push({ pathname: '/' });
      alert(
        'Apologies - an unexpected error occured, please re-enter your preferences'
      );
    }

    ReactGA.pageview('Resort Page');

    ReactGA.event({
      category: 'User_Result',
      action: 'User viewed a resort search result',
    });
  }

  handleSubmitReturn(event) {
    try {
      this.props.history.push({
        pathname: '/resort_list',
      });
    } catch (err) {
      throw new Error(err.message);
    }
  }

  render() {
    let myData = Object.keys(this.state.data).map(
      (key) => this.state.data[key]
    );

    if (myData.length > 5) {
      var extraResorts = true;
    } else {
      extraResorts = false;
    }
    var lastSet = [];
    var displayData = myData.slice(0, 5);
    switch (myData.length) {
      case 8:
        lastSet = [myData[5], myData[6], myData[7]];
        break;
      case 7:
        lastSet = [myData[5], myData[6]];
        break;
      case 6:
        lastSet = [myData[5]];
        break;
      default:
        extraResorts = false;
        break;
    }
    if (extraResorts) {
      return (
        <div>
          <Helmet title={'Matched resorts'} />
          <div className='list-header'>
            <p className='site-logo-header-text'>
              Below are the top 5 resorts that match your criteria, nearest
              match first. We've also thrown in some other resorts you may be
              interested in.
            </p>
          </div>
          <div>
            {displayData.map((resort, index) => (
              <Resort
                key={resort._id}
                data={resort}
                history={this.props.history}
                allData={this.state.data}
                index={index}
              ></Resort>
            ))}
          </div>
          <div className='list-separator'>
            <p className='list-separator-text'>
              Here are some additional resorts that are similar to what you
              asked for and we think may interest you:
            </p>
          </div>
          <div>
            {lastSet.map((resort, index) => (
              <Resort
                key={resort._id}
                data={resort}
                history={this.props.history}
                allData={this.state.data}
                index={index + 5}
              ></Resort>
            ))}
          </div>
          <br></br>
          <br></br>
          <br></br>
          <div className='home-footer'>
            <SimpleBottomNavigation history={this.props.history} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Helmet title={'Matched resorts'} />
          <div className='list-header'>
            <p className='site-logo-header-text'>
              Below are the resorts/resort that match your criteria
            </p>
          </div>
          <div>
            {displayData.map((resort, index) => (
              <Resort
                key={resort._id}
                data={resort}
                history={this.props.history}
                allData={this.state.data}
                index={index}
              ></Resort>
            ))}
          </div>
          <br></br>
          <br></br>
          <br></br>
          <div className='home-footer'>
            <SimpleBottomNavigation history={this.props.history} />
          </div>
        </div>
      );
    }
  }
}

/*
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={pageVariants}
        transition={pageTransition}
      >
    </motion.div>
*/
class Resort extends Component {
  state = {
    data: [],
  };

  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnBook = this.handleOnBook.bind(this);
  }

  handleOnClick(event) {
    if (event.target.name === 'list') {
      this.props.history.push({
        pathname: '/resort_list',
      });
    }
  }

  handleOnBook(event) {
    ReactGA.event({
      category: 'User_Result',
      action: 'User selected a resort',
      label: this.props.data['resort_name'],
    });

    this.props.history.push({
      pathname: '/resort_detail',
      state: this.props.data,
    });
  }

  render() {
    let country = this.props.data['country'];
    let res_id = this.props.data['ID'];
    var resort_bullets = [
      this.props.data['resort_bullet_1'],
      this.props.data['resort_bullet_2'],
      this.props.data['resort_bullet_3'],
      this.props.data['resort_bullet_4'],
      this.props.data['ski_bullet_1'],
      this.props.data['ski_bullet_2'],
      this.props.data['ski_bullet_3'],
      this.props.data['ski_bullet_4'],
    ];

    let logo = imageUrl + res_id + '.png';
    let banner_mobile = imageUrl + res_id + '_wall_mobile.jpg';
    let banner_mobile_small = imageUrl + res_id + '_wall_mobile_small.jpg';
    country = imageUrl + country + '.png';

    return (
      <section className='resort-section'>
        <div className='resort-header'>
          <h2
            value={this.props.data['resort_name']}
            onClick={this.handleOnBook}
            className='resort-header-title'
          >
            {this.props.data['resort_name']}
          </h2>
        </div>
        <section className='resort-info-section'>
          <div className='resort-content-container-new'>
            <div
              id='content-desktop'
              className='parallax-new'
              style={{
                backgroundImage: `url(${banner_mobile})`,
              }}
              alt='resort-banner'
            ></div>
            <img
              id='content-mobile'
              src={banner_mobile}
              alt='resort-banner'
            ></img>
            <img
              id='content-mobile-small'
              src={banner_mobile_small}
              alt='resort-banner'
            ></img>
          </div>
          <div className='resort-content-container-new'>
            <span className='resort-info-header'>
              <img src={logo} alt='icon' className='resort-logo-new'></img>{' '}
              <img src={country} alt='icon' className='resort-flag-new'></img>
              <ResortDesc
                bullets={resort_bullets}
                text={'Our key observations of this resort:'}
              ></ResortDesc>
            </span>
          </div>
        </section>
        <section className='resort-info-section resort-info-section-detail'>
          <MetricTable
            metrics={[
              this.props.data['apres'],
              this.props.data['family_friendly'],
              this.props.data['non_ski'],
              this.props.data['budget'],
            ]}
            labels={['Apres', 'Family friendly', 'Non skiing', 'Budget']}
          ></MetricTable>
          <div className='resort-chart-container'>
            <div className='chart-section'>
              <BarChart
                type='nonski'
                arrayData={this.props.allData}
                index={this.props.index}
              ></BarChart>
            </div>
          </div>
        </section>
        <section className='resort-info-section resort-info-section-detail'>
          <div className='resort-chart-container'>
            <div className='chart-section'>
              <PieGraph
                chart_id='pieChart'
                beginner_ski_area={this.props.data.beginner_ski_area}
                intermediate_ski_area={this.props.data.intermediate_ski_area}
                expert_ski_area={this.props.data.expert_ski_area}
              ></PieGraph>
            </div>
            <br></br>
            <br></br>
            <div className='chart-section'>
              <BarChartSki
                type='ski'
                arrayData={this.props.allData}
                index={this.props.index}
              ></BarChartSki>
            </div>
          </div>
          <MetricTable
            metrics={[
              this.props.data['ski_area'],
              this.props.data['beginner_score'],
              this.props.data['intermediate_score'],
              this.props.data['expert_score'],
              this.props.data['off_piste'],
              this.props.data['lack_crowds'],
              this.props.data['snow_reliability'],
            ]}
            labels={[
              'Total ski area (km)',
              'Beginner area',
              'Intermediate area',
              'Expert area',
              'Off piste',
              'Avoiding crowds',
              'Snow reliability',
            ]}
          ></MetricTable>
        </section>
        <button
          className='resort-btn'
          onClick={this.handleOnBook}
          value={this.props.data['resort_name']}
        >
          Book this resort
        </button>
        <br></br>
        <br></br>
      </section>
    );
  }
}

/*             <div className='info-box'>
              <h4 className='info-header'>Resort info</h4>
              <a href='http://www.verbier.ch/'>Go to resort website</a>
            </div>

                      {bullets.map((bullet, index) => (
            <li className='desc-bullet'>{bullet}</li>
          ))}
*/

class MetricTable extends Component {
  render() {
    var metrics = this.props.metrics;
    var labels = this.props.labels;
    var values = [];
    var i = 0;
    for (i = 0; i < metrics.length; i++) {
      if (labels[i] === 'Budget') {
        switch (metrics[i]) {
          case 1:
            values.push('Expensive');
            break;
          case 2:
            values.push('Higher cost');
            break;
          case 3:
            values.push('Average');
            break;
          case 4:
            values.push('Lower cost');
            break;
          case 5:
            values.push('Very cheap');
            break;
        }
      } else if (
        labels[i] === 'Beginner area' ||
        labels[i] === 'Intermediate area' ||
        labels[i] === 'Expert area'
      ) {
        switch (metrics[i]) {
          case 1:
            values.push('Small/poor');
            break;
          case 2:
            values.push('Below average');
            break;
          case 3:
            values.push('Average');
            break;
          case 4:
            values.push('Good');
            break;
          case 5:
            values.push('Excellent');
            break;
        }
      } else if (labels[i] === 'Apres') {
        switch (metrics[i]) {
          case 1:
            values.push('Quiet');
            break;
          case 2:
            values.push('Below average');
            break;
          case 3:
            values.push('Average');
            break;
          case 4:
            values.push('Good');
            break;
          case 5:
            values.push('Excellent');
            break;
        }
      } else {
        switch (metrics[i]) {
          case 1:
            values.push('Poor');
            break;
          case 2:
            values.push('Below average');
            break;
          case 3:
            values.push('Average');
            break;
          case 4:
            values.push('Good');
            break;
          case 5:
            values.push('Excellent');
            break;
          default:
            values.push(metrics[i]);
        }
      }
    }
    return (
      <table className='metric-table metric-table-ski'>
        <tbody>
          {metrics.map((metric, index) => (
            <tr className='metric-row' key={index}>
              <td className='metric-td-label'>{labels[index]}</td>
              <td className={'metric-td-value metric-td-value-' + metric}>
                {values[index]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

/* Metrics */

class Metric extends Component {
  render() {
    var metric_class = 'metric-card';
    var metric_circle = 'circle';
    var metric_title = this.props.metric_title;
    var final_title =
      metric_title.charAt(0).toUpperCase() + metric_title.slice(1);
    var metric_value = this.props.metric_value;
    var final_value = '';
    if (
      this.props.metric_title === 'Beginner area' ||
      this.props.metric_title === 'Intermediate area' ||
      this.props.metric_title === 'Expert area'
    ) {
      switch (metric_value) {
        case 1:
          final_value = 'Small / poor';
          metric_class = 'metric-card metric-poor';
          break;
        case 2:
          final_value = 'Below average';
          metric_class = 'metric-card metric-below-average';
          break;
        case 3:
          final_value = 'Average';
          metric_class = 'metric-card metric-average';
          break;
        case 4:
          final_value = 'Good';
          metric_class = 'metric-card metric-good';
          break;
        case 5:
          final_value = 'Excellent';
          metric_class = 'metric-card metric-excellent';
          break;
        default:
          final_value = metric_value;
          break;
      }
    } else if (this.props.metric_title !== 'budget') {
      switch (metric_value) {
        case 1:
          final_value = 'Poor';
          metric_class = 'metric-card metric-poor';
          break;
        case 2:
          final_value = 'Below average';
          metric_class = 'metric-card metric-below-average';
          break;
        case 3:
          final_value = 'Average';
          metric_class = 'metric-card metric-average';
          break;
        case 4:
          final_value = 'Good';
          metric_class = 'metric-card metric-good';
          break;
        case 5:
          final_value = 'Excellent';
          metric_class = 'metric-card metric-excellent';
          break;
        default:
          final_value = metric_value;
          break;
      }
    } else
      switch (metric_value) {
        case 1:
          final_value = 'Expensive';
          metric_class = 'metric-card metric-poor';
          break;
        case 2:
          final_value = 'Higher cost';
          metric_class = 'metric-card metric-below-average';
          break;
        case 3:
          final_value = 'Average';
          metric_class = 'metric-card metric-average';
          break;
        case 4:
          final_value = 'Cheap';
          metric_class = 'metric-card metric-good';
          break;
        case 5:
          final_value = 'Very cheap';
          metric_class = 'metric-card metric-excellent';
          break;
        default:
          final_value = metric_value;
          break;
      }
    return (
      <div className='metric'>
        <div className='metric-title'>{final_title}</div>
        <div className={metric_class}>
          <div className='metric-value'>{final_value}</div>
          <div className={metric_circle}></div>
        </div>
      </div>
    );
  }
}

/* RESORT DESCRIPTION */ class PieGraph extends PureComponent {
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext('2d');
    const beginner = this.props.beginner_ski_area;
    const intermediate = this.props.intermediate_ski_area;
    const expert = this.props.expert_ski_area;

    let myPieChart = new Chart(myChartRef, {
      type: 'doughnut',
      data: {
        //Bring in data

        datasets: [
          {
            data: [beginner, intermediate, expert],
            backgroundColor: ['#e7f9ff', '#80bddf', '#282c36'],
          },
        ],
        labels: ['Beginner', 'Intermediate', 'Expert'],
      },
      options: {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem['index']] + '%';
            },
          },
        },
        title: {
          position: 'top',
          display: true,
          text: 'Ski area difficulty',
          fontSize: 17.5,
          fontColor: 'white',
        },
        legend: {
          position: 'bottom',
          labels: {
            fontColor: 'white',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  render() {
    return (
      <div className='graphContainer-mini-new'>
        <canvas id={this.props.chart_id} ref={this.chartRef} />
      </div>
    );
  }
}

class BarChartSki extends PureComponent {
  chartRef = React.createRef();
  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext('2d');
    const arrayData = this.props.arrayData;
    var index = '';
    if (this.props.index >= 0) {
      index = this.props.index;
      arrayData[index]['marker'] = 1;
    }

    let myData = Object.keys(arrayData).map((key) => arrayData[key]);
    var resorts = rankResultsSki(myData);
    var items = ObjectLengthSki(resorts);
    arrayData[index]['marker'] = 0;

    var i = 0;
    var colors = [];
    var labels = [];
    var median_added = false;
    for (i = 0; i < items.num_items; i++) {
      if (i === items.index - 1) {
        colors.push('#ffd700');
        labels.push('This resort');
      } else {
        colors.push('#80bddf');
        labels.push('');
      }

      if (items.data[i] < 100 && median_added === false) {
        items.data.splice(i, 0, 100);
        colors.splice(i, 0, '#282c36');
        labels.splice(i, 0, 'The average resort');
        median_added = true;
      }

      if (
        i + 1 === items.num_items &&
        median_added === false &&
        items.data[i] > 100
      ) {
        items.data.push(100);
        colors.push('#282c36');
        labels.push('The average resort');
        median_added = true;
      }
    }

    let myBarChart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        //Bring in data

        datasets: [
          {
            data: items.data,
            backgroundColor: colors,
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
        labels: labels,
      },
      options: {
        legend: { display: false },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: 'white',
              },
            },
          ],
          yAxes: [
            {
              ticks: { suggestedMin: 0, suggestedMax: 150, fontColor: 'white' },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          position: 'top',
          display: true,
          text: 'Ski area size comparison',
          fontSize: 17.5,
          fontColor: 'white',
        },
      },
    });
  };

  render() {
    return (
      <div className='graphContainer-mini-new'>
        <canvas id={this.props.chart_id} ref={this.chartRef} />
        <p className='graph-helper-text-new'>
          The distance between this resort's index and 100 indicates how large
          the skiing area in relation to other resorts - an index of 150 would
          mean this resort has a ski area 1.5x larger than most resorts, while
          an index of 50 would make it half the size of most resorts.<br></br>
          <br></br>Note that a smaller resort is not necessarily a bad thing -
          these places are usually far less crowded than their larger
          competitors!
        </p>
      </div>
    );
  }
}

class BarChart extends PureComponent {
  chartRef = React.createRef();
  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext('2d');
    const arrayData = this.props.arrayData;
    var index = '';
    if (this.props.index >= 0) {
      index = this.props.index;
      arrayData[index]['marker'] = 1;
    }

    let myData = Object.keys(arrayData).map((key) => arrayData[key]);
    var resorts = rankResults(myData);
    var items = ObjectLength(resorts);
    arrayData[index]['marker'] = 0;

    var i = 0;
    var colors = [];
    var labels = [];
    var median_added = false;
    for (i = 0; i < items.num_items; i++) {
      if (i === items.index - 1) {
        colors.push('#ffd700');
        labels.push('This resort');
      } else {
        colors.push('#80bddf');
        labels.push('');
      }

      if (items.data[i] < 100 && median_added === false) {
        items.data.splice(i, 0, 100);
        colors.splice(i, 0, '#282c36');
        labels.splice(i, 0, 'The average resort');
        median_added = true;
      }

      if (
        i + 1 === items.num_items &&
        median_added === false &&
        items.data[i] > 100
      ) {
        items.data.push(100);
        colors.push('#282c36');
        labels.push('The average resort');
        median_added = true;
      }
    }

    let myBarChart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        //Bring in data

        datasets: [
          {
            data: items.data,
            backgroundColor: colors,
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
        labels: labels,
      },
      options: {
        legend: { display: false },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: 'white',
              },
            },
          ],
          yAxes: [
            {
              ticks: { suggestedMin: 0, suggestedMax: 150, fontColor: 'white' },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          position: 'top',
          display: true,
          text: 'Resort cost comparison',
          fontSize: 17.5,
          fontColor: 'white',
        },
      },
    });
  };

  render() {
    return (
      <div className='graphContainer-max-new'>
        <canvas id={this.props.chart_id} ref={this.chartRef} />
        <p className='graph-helper-text-new'>
          The distance between this resort's index and 100 indicates how
          expensive the average holiday is there - an index of 150 would mean
          this resort is 1.5x more expensive than most resorts, while an index
          of 50 would make it half as expensive. This has been calculated by
          comparing the total costs of a skiing holiday across different
          resorts.<br></br>
          <br></br>Note that a higher resort price index is not necessarily a
          bad thing; it may indicate a resort has more luxury amenities
          available for instance. In addition, even if a resort is cheap on
          average you will still be able to find higher end facilities there
          should you so choose and vice versa.
        </p>
      </div>
    );
  }
}

class ResortDesc extends Component {
  render() {
    var bullets_prefilter = this.props.bullets;

    var bullets = bullets_prefilter.filter(function (el) {
      return el != null;
    });

    return (
      <div className='resort-desc-container'>
        <p className='resort-i'>{this.props.text}</p>
        <ul className='resort-ul'>
          {bullets.map((bullet, index) => (
            <li className='desc-bullet' key={index}>
              {bullet}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

function SimpleBottomNavigation(props) {
  const [value, setValue] = React.useState(4);

  function HandleOnClick(event, newValue) {
    setValue(newValue);
    if (newValue === 0) {
      props.history.push({
        pathname: '/',
      });
    } else if (newValue === 1) {
      props.history.push({
        pathname: '/resort_list',
      });
    }
  }

  return (
    <BottomNavigation value={value} onChange={HandleOnClick} showLabels>
      <BottomNavigationAction label='Home' icon={<HomeSharpIcon />} />
      <BottomNavigationAction
        label='Resort list'
        icon={<FormatListNumberedSharpIcon />}
      />
    </BottomNavigation>
  );
}

function ObjectLength(object) {
  var length = 0;
  var data = [];
  var index = 0;
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      ++length;
      data.push(object[key]['budget_index']);
    }
    if (object[key]['marker'] === 1) {
      index = length;
    }
  }
  var resultObj = { num_items: length, data: data, index: index };
  return resultObj;
}

function ObjectLengthSki(object) {
  var length = 0;
  var data = [];
  var index = 0;
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      ++length;
      data.push(object[key]['ski_area_index']);
    }
    if (object[key]['marker'] === 1) {
      index = length;
    }
  }
  var resultObj = { num_items: length, data: data, index: index };
  return resultObj;
}

function rankResults(resorts) {
  resorts.sort(function (resort_a, resort_b) {
    if (resort_a.budget_index > resort_b.budget_index) return -1;
    if (resort_a.budget_index < resort_b.budget_index) return 1;
    if (
      resort_a.budget_index === resort_b.budget_index &&
      resort_a.calc_rank < resort_b.calc_rank
    )
      return -1;
    if (
      resort_a.budget_index === resort_b.budget_index &&
      resort_a.calc_rank > resort_b.calc_rank
    )
      return 1;
  });
  return resorts;
}

function rankResultsSki(resorts) {
  resorts.sort(function (resort_a, resort_b) {
    if (resort_a.ski_area_index > resort_b.ski_area_index) return -1;
    if (resort_a.ski_area_index < resort_b.ski_area_index) return 1;
    if (
      resort_a.ski_area_index === resort_b.ski_area_index &&
      resort_a.calc_rank < resort_b.calc_rank
    )
      return -1;
    if (
      resort_a.ski_area_index === resort_b.ski_area_index &&
      resort_a.calc_rank > resort_b.calc_rank
    )
      return 1;
  });
  return resorts;
}

export default withRouter(ResortPage);
export { Metric, MetricTable, ResortDesc };
