import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: 'black',
  },
  formControl: { marginLeft: '4px', marginBottom: '15px' },
}));

const BlueCheckbox = withStyles({
  root: {
    color: '#039be5',
    '&$checked': {
      color: '#039be5',
    },
  },
})((props) => <Checkbox color='default' {...props} />);

export default function CheckboxesGroupEurope(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl component='fieldset' className={classes.formControl}>
        <FormLabel component='legend'>Europe</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[0]}
                onChange={props.handleChangeEU}
                name='eu_all'
              />
            }
            label='All'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[1]}
                onChange={props.handleChangeEU}
                name='andorra'
              />
            }
            label='Andorra'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[2]}
                onChange={props.handleChangeEU}
                name='austria'
              />
            }
            label='Austria'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[3]}
                onChange={props.handleChangeEU}
                name='bulgaria'
              />
            }
            label='Bulgaria'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[4]}
                onChange={props.handleChangeEU}
                name='finland'
              />
            }
            label='Finland'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[5]}
                onChange={props.handleChangeEU}
                name='france'
              />
            }
            label='France'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[6]}
                onChange={props.handleChangeEU}
                name='germany'
              />
            }
            label='Germany'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[7]}
                onChange={props.handleChangeEU}
                name='italy'
              />
            }
            label='Italy'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[8]}
                onChange={props.handleChangeEU}
                name='norway'
              />
            }
            label='Norway'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[9]}
                onChange={props.handleChangeEU}
                name='spain'
              />
            }
            label='Spain'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[10]}
                onChange={props.handleChangeEU}
                name='switzerland'
              />
            }
            label='Swizterland'
          />
          <FormControlLabel
            control={
              <BlueCheckbox
                checked={props.countries[11]}
                onChange={props.handleChangeEU}
                name='eu_none'
              />
            }
            label={'None'}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

/*
<FormControl
        required
        error={error}
        component='fieldset'
        className={classes.formControl}
      >
        <FormLabel component='legend'>Pick two</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={gilad} onChange={handleChange} name='gilad' />
            }
            label='Gilad Gray'
          />
          <FormControlLabel
            control={
              <Checkbox checked={jason} onChange={handleChange} name='jason' />
            }
            label='Jason Killian'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={antoine}
                onChange={handleChange}
                name='antoine'
              />
            }
            label='Antoine Llorca'
          />
        </FormGroup>
        <FormHelperText>You can display an error</FormHelperText>
      </FormControl>
*/
