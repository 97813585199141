import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { config } from './Constants';
import axios from 'axios';
import { Component } from 'react';

var url = config.url.API_URL;

export default class searchBar extends Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  state = {
    list: [''],
  };

  handleOnChange(event, list) {
    console.log(event.target.value);
    if (event.key === 'Enter' && event.target.value !== '') {
      var i = 0;
      for (i = 0; i < list.length; i++) {
        console.log(list[i].substring(0, list[i].length - 6));
        console.log(
          list[i].substring(list[i].length - 6).replace(/[^a-zA-Z ]/g, '')
        );
        if (event.target.value === list[i].substring(0, list[i].length - 6)) {
          getResortData(
            this.props.history,
            list[i].substring(list[i].length - 6).replace(/[^a-zA-Z ]/g, '')
          );
        } else continue;
      }
    } else if (event.key === 'Enter' && event.target.value !== '') {
      alert('Resort not found');
    }
  }

  setAutoCompleteValue(value) {
    console.log(value);
  }

  render() {
    var list = [''];
    try {
      if (this.props.options.length < 1) {
        list = [''];
      } else {
        list = this.props.options;
      }
    } catch {
      list = [''];
    }

    return (
      <Autocomplete
        id='blur-on-select'
        options={list}
        getOptionLabel={(option) => option.substring(0, option.length - 6)}
        onKeyPress={(event) => this.handleOnChange(event, list)}
        style={{ width: 350, padding: 10 }}
        renderInput={(params) => (
          <TextField {...params} label='Search resort' />
        )}
      />
    );
  }
}

const getResortData = async (props, query) => {
  const getResponse = await axios.get(url + 'find_resort/' + query);
  const data = { 0: { ...getResponse.data } };
  props.push({
    pathname: '/resort_page',
    state: { detail: data },
  });
};
