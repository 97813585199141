import React from 'react';
import { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { config } from './Constants';
import FormSelect from './FormSelect';
import ReactGA from 'react-ga';
import CheckboxesGroupEurope from './FormCountry';
import CheckboxesGroupNA from './FormCountryNA';

var url = config.url.API_URL;

class FormTest extends Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnHelp = this.handleOnHelp.bind(this);
    this.handleChangeNA = this.handleChangeNA.bind(this);
    this.handleChangeEU = this.handleChangeEU.bind(this);
    this.handleOnBack = this.handleOnBack.bind(this);

    this.state = {
      begScore: 5,
      intScore: 5,
      expScore: 5,
      begPref: false,
      intPref: false,
      expPref: false,
      offPiste: false,
      offPisteError: false,
      offPisteErrorText: '',
      apres: false,
      apresError: false,
      apresErrorText: '',
      familyFriendly: false,
      familyFriendlyError: false,
      familyFriendlyErrorText: '',
      nonSki: false,
      nonSkiError: false,
      nonSkiErrorText: '',
      country: false,
      countryError: false,
      countryErrorText: false,
      budget: false,
      budgetError: false,
      budgetErrorText: '',
      responseData: {},
      submit: 'Find your perfect resort',
      na_all: true,
      usa: true,
      canada: true,
      na_none: false,
      eu_all: true,
      andorra: true,
      austria: true,
      bulgaria: true,
      finland: true,
      france: true,
      germany: true,
      italy: true,
      norway: true,
      spain: true,
      switzerland: true,
      eu_none: false,
    };
  }

  handler(event, fieldname) {
    const name = fieldname;
    const value = event.target.value;
    if (value === '') {
      this.setState({
        [name]: '',
        [name + 'Error']: true,
        [name + 'ErrorText']: 'Please select a preference',
      });
    } else {
      this.setState({
        [name]: event.target.value,
        [name + 'Error']: false,
        [name + 'ErrorText']: '',
      });
    }
  }

  handleOnChange(e) {
    if (e.target.id === 'S1') {
      const move_1 = Math.round(e.target.value - this.state.begScore);
      this.setState({ begScore: e.target.value });

      // move_1 increase
      if (move_1 > 0) {
        if (this.state.intScore > 0 && this.state.expScore > 0) {
          this.setState({
            intScore: this.state.intScore - move_1 / 2,
          });
          this.setState({
            expScore: this.state.expScore - move_1 / 2,
          });
        } else if (this.state.intScore === 0) {
          this.setState({ expScore: this.state.expScore - move_1 });
        } else {
          this.setState({ intScore: this.state.intScore - move_1 });
        }
      }

      // move_1 decrease
      else if (move_1 < 0) {
        if (this.state.intScore < 10 && this.state.expScore < 10) {
          this.setState({
            intScore: this.state.intScore - move_1 / 2,
          });
          this.setState({
            expScore: this.state.expScore - move_1 / 2,
          });
        } else if (this.state.intScore === 10) {
          this.setState({ expScore: this.state.expScore - move_1 });
        } else {
          this.setState({ intScore: this.state.intScore - move_1 });
        }
      }
    } else if (e.target.id === 'S2') {
      const move_2 = Math.round(e.target.value - this.state.intScore);
      this.setState({ intScore: e.target.value });

      // if move 2 is an increase
      if (move_2 > 0) {
        if (this.state.begScore > 0 && this.state.expScore > 0) {
          this.setState({ begScore: this.state.begScore - move_2 / 2 });
          this.setState({ expScore: this.state.expScore - move_2 / 2 });
        } else if (this.state.begScore === 0) {
          this.setState({ expScore: this.state.expScore - move_2 });
        } else {
          this.setState({ begScore: this.state.begScore - move_2 });
        }
      } else if (move_2 < 0) {
        if (this.state.begScore < 10 && this.state.expScore < 10) {
          this.setState({ begScore: this.state.begScore - move_2 / 2 });
          this.setState({ expScore: this.state.expScore - move_2 / 2 });
        } else if (this.state.begScore === 10) {
          this.setState({ expScore: this.state.expScore - move_2 });
        } else {
          this.setState({ begScore: this.state.begScore - move_2 });
        }
      }
    } else if (e.target.id === 'S3') {
      const move_3 = Math.round(e.target.value - this.state.expScore);
      this.setState({ expScore: e.target.value });

      // if move 3 is an increase
      if (move_3 > 0) {
        if (this.state.begScore > 0 && this.state.intScore > 0) {
          this.setState({ begScore: this.state.begScore - move_3 / 2 });
          this.setState({ intScore: this.state.intScore - move_3 / 2 });
        } else if (this.state.begScore === 0) {
          this.setState({ intScore: this.state.intScore - move_3 });
        } else {
          this.setState({ begScore: this.state.begScore - move_3 });
        }
      } else if (move_3 < 0) {
        if (this.state.begScore < 10 && this.state.intScore < 10) {
          this.setState({ begScore: this.state.begScore - move_3 / 2 });
          this.setState({ intScore: this.state.intScore - move_3 / 2 });
        } else if (this.state.begScore === 10) {
          this.setState({ intScore: this.state.intScore - move_3 });
        } else {
          this.setState({ begScore: this.state.begScore - move_3 });
        }
      }
    }
  }

  handleSubmit = async (e) => {
    if (this.state.submit === 'Find your perfect resort') {
      e.preventDefault();
      this.setState({ submit: 'Last step' });
    } else if (this.state.submit === 'Last step') {
      e.preventDefault();
      this.setState({ submit: 'Show my results' });
    } else {
      this.setState({ submit: 'Loading' });
      var country_q = 0;
      var countries = [
        { name: 'USA', value: this.state.usa },
        { name: 'Canada', value: this.state.canada },
        { name: 'andorra', value: this.state.andorra },
        { name: 'austria', value: this.state.austria },
        { name: 'bulgaria', value: this.state.bulgaria },
        { name: 'finland', value: this.state.finland },
        { name: 'france', value: this.state.france },
        { name: 'germany', value: this.state.germany },
        { name: 'italy', value: this.state.italy },
        { name: 'norway', value: this.state.norway },
        { name: 'spain', value: this.state.spain },
        { name: 'switzerland', value: this.state.switzerland },
      ];

      if ((this.state.na_none === true) & (this.state.eu_none === true)) {
        country_q = 0;
      } else if ((this.state.na_all === true) & (this.state.eu_all === true)) {
        country_q = 0;
      } else {
        for (i = 0; i < countries.length; i++) {
          if ((countries[i].value === true) & (country_q === 0)) {
            country_q =
              countries[i].name.charAt(0).toUpperCase() +
              countries[i].name.slice(1);
          } else if (countries[i].value === true) {
            country_q =
              country_q +
              '+' +
              countries[i].name.charAt(0).toUpperCase() +
              countries[i].name.slice(1);
          }
        }
      }

      const result_arr = [
        this.state.offPiste,
        this.state.familyFriendly,
        this.state.apres,
        this.state.nonSki,
        this.state.budget,
      ];
      var err = 0;
      var noPref = 0;
      var begPref = this.state.begScore;
      var intPref = this.state.intScore;
      var expPref = this.state.expScore;

      var i = 0;
      for (i = 0; i < result_arr.length; i++) {
        if (result_arr[i] === false || result_arr[i] === '') {
          err++;
        }
      }
      if (err > 0) {
        this.setState({ submit: 'Show my results' });
        alert('Please set every preference');
        e.preventDefault();
      } else {
        this.setState({ submit: 'Show my results' });
        e.preventDefault();
        try {
          var q_url =
            url +
            'q/' +
            String(begPref) +
            '&' +
            String(intPref) +
            '&' +
            String(expPref) +
            '&' +
            String(noPref) +
            '&' +
            String(this.state.begScore) +
            '&' +
            String(this.state.intScore) +
            '&' +
            String(this.state.expScore) +
            '&' +
            String(this.state.offPiste) +
            '&' +
            String(this.state.apres) +
            '&' +
            String(this.state.familyFriendly) +
            '&' +
            String(this.state.nonSki) +
            '&' +
            String(country_q) +
            '&' +
            String(this.state.budget);
          try {
            console.log(q_url);
            const responseData = await getData(q_url);
            ReactGA.event({
              category: 'User_Search',
              action: 'User submitted a resort search',
              value: 1,
            });
            this.setState({ responseData: responseData });
            this.props.history.push({
              pathname: '/resort_page',
              state: { detail: this.state.responseData },
            });
          } catch (err) {
            throw new Error(err.message);
          }
        } catch (err) {
          throw new Error(err.message);
        }
      }
    }
  };

  handleOnHelp(event) {
    event.preventDefault();
    this.props.history.push({
      pathname: '/howitworks',
    });
  }

  handleOnBack(event) {
    if (event.target.name === '1-back') {
      this.setState({ submit: 'Find your perfect resort' });
    } else this.setState({ submit: 'Last step' });
  }

  handleChangeNA = (event) => {
    if (event.target.name === 'na_all') {
      this.setState({
        na_all: event.target.checked,
        usa: event.target.checked,
        canada: event.target.checked,
        na_none: false,
      });
    } else if (event.target.name === 'na_none') {
      this.setState({
        na_all: false,
        usa: false,
        canada: false,
        na_none: event.target.checked,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.checked,
        na_none: false,
        na_all: false,
      });
    }
  };

  handleChangeEU = (event) => {
    if (event.target.name === 'eu_all') {
      this.setState({
        eu_all: event.target.checked,
        andorra: event.target.checked,
        austria: event.target.checked,
        bulgaria: event.target.checked,
        finland: event.target.checked,
        france: event.target.checked,
        germany: event.target.checked,
        italy: event.target.checked,
        norway: event.target.checked,
        spain: event.target.checked,
        switzerland: event.target.checked,
        eu_none: false,
      });
    } else if (event.target.name === 'eu_none') {
      this.setState({
        eu_all: false,
        andorra: false,
        austria: false,
        bulgaria: false,
        finland: false,
        france: false,
        germany: false,
        italy: false,
        norway: false,
        spain: false,
        switzerland: false,
        eu_none: event.target.checked,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.checked,
        eu_none: false,
        eu_all: false,
      });
    }
  };

  render() {
    let formContent;
    if (this.state.submit === 'Find your perfect resort') {
      formContent = (
        <section className='form-section'>
          <form onSubmit={this.handleSubmit}>
            <h3 className='form-title'>
              Which countries are you interested in looking at?
            </h3>
            <CheckboxesGroupEurope
              name='country-eu'
              handleChangeEU={this.handleChangeEU.bind(this)}
              countries={[
                this.state.eu_all,
                this.state.andorra,
                this.state.austria,
                this.state.bulgaria,
                this.state.finland,
                this.state.france,
                this.state.germany,
                this.state.italy,
                this.state.norway,
                this.state.spain,
                this.state.switzerland,
                this.state.eu_none,
              ]}
            ></CheckboxesGroupEurope>
            <CheckboxesGroupNA
              name='country-na'
              handleChange={this.handleChangeNA.bind(this)}
              countries={[
                this.state.na_all,
                this.state.usa,
                this.state.canada,
                this.state.na_none,
              ]}
            ></CheckboxesGroupNA>
            <input
              type='submit'
              value={this.state.submit}
              className='form-submit'
              id='submit-btn'
            />
          </form>
          <span className='dot-container'>
            <span className='dot dot-complete'></span>
            <span className='dot'></span>
            <span className='dot'></span>
          </span>
        </section>
      );
    } else if (this.state.submit === 'Last step') {
      formContent = (
        <section className='form-section'>
          <form onSubmit={this.handleSubmit}>
            <h3 className='form-title'>
              Which skiing level is more important for you?
            </h3>
            <div className='slider-container'>
              <label className='slider-label'>
                Beginner
                <br></br>
                <input
                  id='S1'
                  type='range'
                  min={0}
                  max={10}
                  value={this.state.begScore}
                  className='slider-beg'
                  onChange={this.handleOnChange}
                ></input>
              </label>
            </div>
            <div className='slider-container'>
              <label className='slider-label'>
                Intermediate
                <br></br>
                <input
                  id='S2'
                  type='range'
                  min={0}
                  max={10}
                  value={this.state.intScore}
                  className='slider slider-int'
                  onChange={this.handleOnChange}
                  label='intermediate skiing slider'
                />
              </label>
            </div>
            <div className='slider-container'>
              <label className='slider-label'>
                Expert
                <br></br>
                <input
                  id='S3'
                  type='range'
                  min={0}
                  max={10}
                  value={this.state.expScore}
                  className='slider-exp'
                  onChange={this.handleOnChange}
                  label='expert skiing slider'
                />
              </label>
            </div>
            <input
              type='submit'
              value={this.state.submit}
              className='form-submit'
              id='submit-btn'
            />

            <button
              className='form-back'
              name='1-back'
              onClick={this.handleOnBack}
            >
              Go back
            </button>
          </form>
          <span className='dot-container'>
            <span className='dot dot-complete'></span>
            <span className='dot dot-complete'></span>
            <span className='dot'></span>
          </span>
        </section>
      );
    } else if (this.state.submit === 'Show my results') {
      formContent = (
        <section className='form-section'>
          <form onSubmit={this.handleSubmit}>
            <h3 className='form-title'>
              What are your resort preferences out of 5?
            </h3>
            <div className='select-container'>
              <label className='input-label' id='budget'>
                <FormSelect
                  label='budget'
                  action={(event) => this.handler(event, 'budget')}
                  name='Budget'
                  error={this.state.budgetError}
                  errorText={this.state.budgetErrorText}
                />
              </label>
              <label className='input-label' id='apres'>
                <FormSelect
                  label='apres preference'
                  action={(event) => this.handler(event, 'apres')}
                  name='Apres'
                  error={this.state.apresError}
                  errorText={this.state.apresErrorText}
                />
              </label>
              <label className='input-label' id='fam'>
                <FormSelect
                  label='family friendly preference'
                  action={(event) => this.handler(event, 'familyFriendly')}
                  name='Family Friendliness'
                  error={this.state.familyFriendlyError}
                  errorText={this.state.familyFriendlyErrorText}
                />
              </label>
              <label className='input-label' id='offPiste'>
                <FormSelect
                  label='off piste preference'
                  action={(event) => this.handler(event, 'offPiste')}
                  name='Off Piste'
                  error={this.state.offPisteError}
                  errorText={this.state.offPisteErrorText}
                />
              </label>

              <label className='input-label' id='non'>
                <FormSelect
                  label='non skiing preference'
                  action={(event) => this.handler(event, 'nonSki')}
                  name='Non Skiing Activities'
                  error={this.state.nonSkiError}
                  errorText={this.state.nonSkiErrorText}
                />
              </label>
            </div>
            <input
              type='submit'
              value={this.state.submit}
              className='form-submit'
              id='submit-btn'
            />
            <button
              className='form-back'
              name='2-back'
              onClick={this.handleOnBack}
            >
              Go back
            </button>
          </form>
          <span className='dot-container'>
            <span className='dot dot-complete'></span>
            <span className='dot dot-complete'></span>
            <span className='dot dot-complete'></span>
          </span>
        </section>
      );
    }

    return <div>{formContent}</div>;
  }
}

export default withRouter(FormTest);

const getData = async (url) => {
  const getResponse = await axios.get(url);
  return { ...getResponse.data };
};
